import { createContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import client from '../../client';

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [hit, setHit] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      const uid = localStorage.getItem('uid')
      const query = `*[_type == "user" && _id == "${uid}"]`
      const userData = await client.fetch(query)
      setUser(userData[0])
    }

    const userId = localStorage.getItem('uid')
    if(userId) {
      fetchUserData()
    }
  }, [hit])


  return (
    <UserContext.Provider value={{ user, setUser, setHit, hit }}>
      {children}
    </UserContext.Provider>
  )
}
