import { createContext, useState, useEffect } from 'react'

export const RecentlyViewedContext = createContext()

export const RecentlyViewedProvider = ({ children }) => {
  const [products, setProducts] = useState([])

  // Load products from localStorage when the component mounts
  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('recentlyViewed'))
    if (storedProducts) {
      setProducts(storedProducts)
    }
  }, [])

  const addProducts = (product) => {
    const storedProducts =
      JSON.parse(localStorage.getItem('recentlyViewed')) || []
    if (product && !storedProducts.some((x) => x._id === product._id)) {
      setProducts((prevProducts) => {
        const updatedProducts = [...prevProducts, product]
        localStorage.setItem('recentlyViewed', JSON.stringify(updatedProducts))
        return updatedProducts
      })
    }
  }

  const removeProducts = (product) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.filter((x) => x._id !== product._id)
      localStorage.setItem('recentlyViewed', JSON.stringify(updatedProducts))
      return updatedProducts
    })
  }

  return (
    <RecentlyViewedContext.Provider
      value={{ products, addProducts, removeProducts }}
    >
      {children}
    </RecentlyViewedContext.Provider>
  )
}
