import { createContext, useEffect, useState } from 'react'
import { SessionProvider } from 'next-auth/react'
import '../styles/styles.scss'
import { UserProvider } from '../context/UserContext'
import '../styles/css/product.css'
import '../styles/css/global.css'
import { Analytics } from '@vercel/analytics/react'
import { useRouter } from 'next/router'
import {
  RecentlyViewedContext,
  RecentlyViewedProvider,
} from 'context/RecentlyViewed'

// Export
export const CartContext = createContext()
const MyApp = ({ Component, pageProps }) => {
  // Hook Assignment
  const [cart, setCart] = useState([])
  const [subscriptionCart, setSubscriptionCart] = useState([])
  const [subscriptionPlan, setSubscriptionPlan] = useState('')

  const [payment, setPayment] = useState(0)
  const route = useRouter()
  const [memberShipFilter, setMemberShipFilter] = useState({
    oneTime: true,
    everyTime: false,
  })
  const [address, setAddress] = useState([])
  const [discount, setDiscount] = useState(0)
  const [note, setNote] = useState('')
  const [couponId, setCouponId] = useState('')
  const [checkoutPage, setCheckoutPage] = useState(false)
  const [wishlist, setWishlist] = useState([])
  const [userCoupon, setUserCoupon] = useState(false)
  const [sort, setSort] = useState()
  const [redeemPoint, setRedeemPoint] = useState(0)
  const [referralDiscount, setReferralDiscount] = useState(0)

  useEffect(() => {
    const localCart = localStorage.getItem('cart')
    if (localCart) {
      setCart(JSON.parse(localCart))
    }
  }, [])
  useEffect(() => {
    const localSubscriptionCart = localStorage.getItem('subscriptionCart')
    if (localSubscriptionCart) {
      setSubscriptionCart(JSON.parse(localSubscriptionCart))
    }
  }, [])
  useEffect(() => {
    const subscriptionPlan = localStorage.getItem('subscriptionPlan')
    if (subscriptionPlan) {
      setSubscriptionPlan(subscriptionPlan)
    }
  }, [])

  useEffect(() => {
    const localwishlist = localStorage.getItem('wishlist')
    if (localwishlist) {
      setWishlist(JSON.parse(localwishlist))
    }
  }, [])
  useEffect(() => {
    const localMember = localStorage.getItem('member')
    if (localMember) {
      setMemberShipFilter(JSON.parse(localMember))
    }
  }, [])
  useEffect(() => {
    const localAddress = localStorage.getItem('address')
    if (localAddress) {
      setAddress(localAddress)
    }
  }, [])
  useEffect(() => {
    const localPayment = localStorage.getItem('payment')
    if (localPayment) {
      setPayment(localPayment)
    }
  }, [])
  useEffect(() => {
    const localDiscount = localStorage.getItem('discount')
    if (localDiscount) {
      setDiscount(localDiscount)
    }
  }, [])
  useEffect(() => {
    const localCoupon = localStorage.getItem('coupon')
    if (localCoupon) {
      setCouponId(localCoupon)
    }
  }, [])
  useEffect(() => {
    const localCheckout = localStorage.getItem('checkout')
    if (localCheckout) {
      setCheckoutPage(localCheckout)
    }
  }, [])
  useEffect(() => {
    const localNote = localStorage.getItem('note')
    if (localNote) {
      setPayment(localNote)
    }
  }, [])
  useEffect(() => {
    const localRedeemCoupon = localStorage.getItem('redeemCoupon')
    if (localRedeemCoupon) {
      setRedeemPoint(localRedeemCoupon)
    }
  }, [])

  useEffect(() => {
    const localReferralDiscount = localStorage.getItem('referralDiscount')
    if (localReferralDiscount) {
      setReferralDiscount(localReferralDiscount)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart))
  }, [cart])
  useEffect(() => {
    localStorage.setItem('subscriptionCart', JSON.stringify(subscriptionCart))
  }, [subscriptionCart])
  useEffect(() => {
    localStorage.setItem('subscriptionPlan', subscriptionPlan)
  }, [subscriptionPlan])

  useEffect(() => {
    localStorage.setItem('wishlist', JSON.stringify(wishlist))
  }, [wishlist])
  useEffect(() => {
    localStorage.setItem('payment', payment)
  }, [payment])
  useEffect(() => {
    localStorage.setItem('address', address)
  }, [address])
  useEffect(() => {
    localStorage.setItem('member', JSON.stringify(memberShipFilter))
  }, [memberShipFilter])
  useEffect(() => {
    localStorage.setItem('discount', discount)
  }, [discount])
  useEffect(() => {
    localStorage.setItem('note', note)
  }, [note])
  useEffect(() => {
    localStorage.setItem('coupon', couponId)
  }, [couponId])
  useEffect(() => {
    localStorage.setItem('checkout', checkoutPage)
  }, [checkoutPage])
  useEffect(() => {
    localStorage.setItem('redeemCoupon', redeemPoint)
  }, [])
  useEffect(() => {
    localStorage.setItem('referralDiscount', referralDiscount)
  }, [])

  useEffect(() => {
    if (route.pathname === '/api/auth/error') {
      route.push('/login')
    }
  }, [route])

  return (
    <UserProvider>
      <SessionProvider session={pageProps.session}>
        <CartContext.Provider
          value={{
            cart,
            setCart,
            subscriptionCart,
            setSubscriptionCart,
            subscriptionPlan,
            setSubscriptionPlan,
            wishlist,
            setWishlist,
            payment,
            setPayment,
            memberShipFilter,
            setMemberShipFilter,
            setAddress,
            address,
            setDiscount,
            discount,
            note,
            setNote,
            setCouponId,
            couponId,
            checkoutPage,
            setCheckoutPage,
            userCoupon,
            setUserCoupon,
            sort,
            setSort,
            referralDiscount,
            setReferralDiscount,
            redeemPoint,
            setRedeemPoint,
          }}
        >
          <RecentlyViewedProvider>
            <Component {...pageProps} />
          </RecentlyViewedProvider>
          <Analytics />
        </CartContext.Provider>
      </SessionProvider>
    </UserProvider>
  )
}
export default MyApp
